/* Yachay Project - App.js 
 * Ricardo Silvestre - 18/07/2023 
 * Version: YachaY DBM v2.9.3
 */

import React, { useState, useEffect } from 'react';
import logo from './images/logo.png';
import './App.css';

import Login from './Login';

import CompanyPortal from './CompanyPortal';
import UniversityLocal_Certification from './UniversityLocal_Certification';
import UniversityPortal_Certification from './UniversityPortal_Certification';
import UniversityPortal_AllEquivalences from './UniversityPortal_Equivalences';
import UniversityPortal_Courses from './UniversityPortal_Courses';
import UniversityPortal_ManageCompanies from './UniversityPortal_ManageCompanies';

import BlockchainIntegration from './BlockchainIntegration';

import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom';
import TabSelector from './TabSelector';


import axios from 'axios';
import {UrlBackend} from './Url';



function App() { 
  
  const connection_type = 'local'; //local or remote
  if(connection_type === 'local'){
    //When in local should use the default baseURL
    axios.defaults.baseURL = 'http://localhost:3000';
  }else if(connection_type === 'remote'){
    //Setting up axios to use the remote server
    axios.defaults.baseURL = UrlBackend;
  }
  console.log("App.js - connection_type: " + connection_type + "|| Back-End URL:" + UrlBackend);


  const [selectionTab, setSelectionTab] = useState(1);
  const [activeTab, setActiveTab] = useState('');
  
  const [universities, setUniversities] = useState([]);
  const [deleteId, setDeleteId] = useState('');
  const [updateId, setUpdateId] = useState('');
  const [updateName, setUpdateName] = useState('');
  const [selectedUniversityId, setSelectedUniversityId] = useState('');

  const [courses, setCourses] = useState([]);
  const [deleteCourseId, setDeleteCourseId] = useState('');
  const [updateCourseID, setUpdateCourseID] = useState('');
  const [updateCourseName, setUpdateCourseName] = useState('');
  const [updateCourseField, setUpdateCourseField] = useState('');
  const [updateCourseTopic, setUpdateCourseTopic] = useState('');
  //const [selectedCourseId, setSelectedCourseId] = useState('');

  const [companies, setCompanies] = useState([]);
  const [deleteCompanyId, setDeleteCompanyId] = useState('');
  const [updateCompanyId, setUpdateCompanyId] = useState('');
  const [updateCompanyName, setUpdateCompanyName] = useState('');
  const [selectedCompanyId, setSelectedCompanyId] = useState('');

  const [jobs, setJobs] = useState([]);
  const [deleteJobId, setDeleteJobId] = useState('');
  const [updateJobId, setUpdateJobId] = useState('');
  const [updateJobName, setUpdateJobName] = useState('');
  const [selectedJobId, setSelectedJobId] = useState('');

  const [jobCertificates, setJobCertificates] = useState([]);
  const [deleteJobCertificateId, setDeleteJobCertificateId] = useState('');
  const [updateJobCertificateId, setUpdateJobCertificateId] = useState('');
  const [updateJobCertificateName, setUpdateJobCertificateName] = useState('');
  const [updateJobCertificateCourseID,setUpdateJobCertificateCourseID] = useState('');
  const [updateJobCertificateCourseName, setUpdateJobCertificateCourseName] = useState('');
  //const [selectedJobCertificateId, setSelectedJobCertificateId] = useState('');

  const [users, setUsers] = useState([]);
  const [deleteUserId, setDeleteUserId] = useState('');
  const [updateUserId, setUpdateUserId] = useState('');
  const [updateUserName, setUpdateUserName] = useState('');
  const [selectedUserId, setSelectedUserId] = useState('');

  const [certificates, setCertificates] = useState([]);
  const [deleteCertificateId, setDeleteCertificateId] = useState('');
  const [updateCertificateId, setUpdateCertificateId] = useState('');
  const [updateCourseId, setUpdateCourseId] = useState('');
  const [updateGrade, setUpdateGrade] = useState('');
  const [updateDate, setUpdateDate] = useState('');
  const [selectedCertificateId, setSelectedCertificateId] = useState('');

  const [equivalences, setEquivalences] = useState([]);
  const [updateEquivalenceId, setUpdateEquivalenceId] = useState('');
  const [deleteEquivalenceId, setDeleteEquivalenceId] = useState('');
  const [updateEquivalenceTargetUni, setUpdateEquivalenceTargetUni] = useState('');
  const [updateEquivalenceTargetCourse, setUpdateEquivalenceTargetCourse] = useState('');
  const [updateEquivalenceDate, setUpdateEquivalenceDate] = useState('');  
  //const [selectedEquivalenceId, setSelectedEquivalenceId] = useState('');

  const [professionalCertificates, setProfessionalCertificates] = useState([]);
  const [deleteProfessionalCertificateId, setDeleteProfessionalCertificateId] = useState('');
  const [updateProfessionalCertificateId, setUpdateProfessionalCertificateId] = useState('');
  const [updateProfessionalCourseId, setUpdateProfessionalCourseId] = useState('');
  const [updateProfessionalGrade, setUpdateProfessionalGrade] = useState('');
  const [updateProfessionalDate, setUpdateProfessionalDate] = useState('');
  const [selectedProfessionalCertificateId, setSelectedProfessionalCertificateId] = useState('');

  const [professionalEquivalences, setProfessionalEquivalences] = useState([]);
  const [updateProfessionalEquivalenceId, setUpdateProfessionalEquivalenceId] = useState('');
  const [deleteProfessionalEquivalenceId, setDeleteProfessionalEquivalenceId] = useState('');
  const [updateProfessionalEquivalenceTargetUni, setUpdateProfessionalEquivalenceTargetUni] = useState('');
  const [updateProfessionalEquivalenceTargetCourse, setUpdateProfessionalEquivalenceTargetCourse] = useState('');
  const [updateProfessionalEquivalenceDate, setUpdateProfessionalEquivalenceDate] = useState('');  


//Authentication
const [accessLevel, setAccessLevel] = useState('');
const [accessUsernameID, setAccessUsernameID] = useState('');

const fetchAccessLevel = async () => {  
    // Fetch the access level from local storage or state management
    const storedAccessLevel = localStorage.getItem('accessLevel');
    const storedAccessUsername = localStorage.getItem('username');
    setAccessLevel(storedAccessLevel);
    setAccessUsernameID(parseInt(storedAccessUsername.slice(-1)));
    if (storedAccessLevel === 'university') {
      setSelectedUniversityId(accessUsernameID);
      //handleUniversityClick(accessUsernameID);
      handleUniversityClick(parseInt(storedAccessUsername.slice(-1)));
    console.log("storedAccessLevel: ", accessLevel);     
    console.log("storedAccessUsernameID: ", accessUsernameID);
    };
};



  const handleCertificationVerification = async (certificationType) => {
    //triggers when a new equivalence academic or professional is created in the database

    try {     
      if (certificationType === 'academic') {        
        const academicResponse = await axios.get(`/verification/academic`);
        const {certificate, equivalence} = academicResponse.data;

        const academicData = {          
          certificate,          
          equivalence,          
        };  
       
        const academicSection = document.getElementById('json-section-academic');
        academicSection.innerText = JSON.stringify(academicData, null, 2);
        

      } else if (certificationType === 'professional') {          
        const professionalResponse = await axios.get(`/verification/professional`);
        const {jobCertificate, professionalCertificate, professionalEquivalence} = professionalResponse.data;
        console.log(professionalResponse.data);

        const professionalData = {     
          jobCertificate,     
          professionalCertificate,          
          professionalEquivalence,          
        };    
        //console.log("professionalData: ", professionalData);
        const professionalSection = document.getElementById('json-section-professional');
        professionalSection.innerText = JSON.stringify(professionalData, null, 2);
      }      
    } catch (error) {
      console.error('Error fetching data:', error);      
    }
  };




  useEffect(() => {
    fetchUniversities();
  }, []);

  const fetchUniversities = async () => {
    try {
      const response = await axios.get('/read');
      setUniversities(response.data);
    } catch (error) {
      console.error('Error fetching universities:', error);
    }
  };

  const fetchCourses = async () => {
    const universityId = selectedUniversityId;
    try {
      const response = await axios.get(`/courses/${universityId}`);
      setCourses(response.data);
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  const fetchCompanies = async () => {
    const universityId = selectedUniversityId;
    try {
      const response = await axios.get(`/companies/${universityId}`);
      setCompanies(response.data);
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  };

  const fetchJobs = async () => {
    const companyId = selectedCompanyId;
    try {
      const response = await axios.get(`/jobs/${companyId}`);
      setJobs(response.data);
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching jobs:', error);
    }
  };

  const fetchJobCertificates = async () => {
    const jobId = selectedJobId;
    try {
      const response = await axios.get(`/jobCertificates/${jobId}`);
      setJobCertificates(response.data);
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching jobs certificates:', error);
    }
  };



  const fetchUsers = async (universityId) => {
    try {
      const response = await axios.get(`/users/${universityId}`);
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchCertificates = async (userId) => {
    try {
      const response = await axios.get(`/certificates/${userId}`);
      setCertificates(response.data);
    } catch (error) {
      console.error('Error fetching certificates:', error);
    }
  };

  const fetchEquivalences = async (universityId, certificateId) => {
    try {
      const response = await axios.get(`/equivalences/${universityId}/${certificateId}`);
      setEquivalences(response.data);
    } catch (error) {
      console.error('Error fetching equivalences:', error);
    }
  };

  const fetchProfessionalCertificates = async (userId) => {
    try {
      const response = await axios.get(`/professionalcertificates/${userId}`);
      setProfessionalCertificates(response.data);
    } catch (error) {
      console.error('Error fetching professional certificates:', error);
    }
  };

  const fetchProfessionalEquivalences = async (universityId, professionalcertificateId) => {
    try {
      const response = await axios.get(`/professionalequivalences/${universityId}/${professionalcertificateId}`);
      setProfessionalEquivalences(response.data);
    } catch (error) {
      console.error('Error fetching professional equivalences:', error);
    }
  };
  

  const handleInsert = async (event) => {
    event.preventDefault();
    const universityName = event.target.elements.universityName.value;

    try {
      await axios.post('/insert', { universityName });
      event.target.reset();
      fetchUniversities();
    } catch (error) {
      console.error('Error inserting university:', error);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`/delete/${deleteId}`);
      setDeleteId('');
      fetchUniversities();
    } catch (error) {
      console.error('Error deleting university:', error);
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();

    try {
      await axios.put(`/update/${updateId}`, { universityName: updateName });
      setUpdateId('');
      setUpdateName('');
      fetchUniversities();
    } catch (error) {
      console.error('Error updating university:', error);
    }
  };

  const handleUniversityClick = async (universityId) => {
    if (universityId === selectedUniversityId) {
      setSelectedUniversityId('');
      setUsers([]);
      setCompanies([]);
      //missing set pf certificates
      setCertificates([]);
      setCourses([])
    } else {
      setSelectedUniversityId(universityId);

      //highlight selected table row - in university table the selected row is highlighted
      const rows = document.querySelectorAll('.table tbody tr');
      rows.forEach((row) => {
        if (row.dataset.universityId === universityId) {
          row.classList.add('selected');
        } else {
          row.classList.remove('selected');
        }
      });
     
      try {
        const usersResponse = await axios.get(`/users/${universityId}`);
        setUsers(usersResponse.data);

        const companiesResponse = await axios.get(`/companies/${universityId}`);
        setCompanies(companiesResponse.data);

        const coursesResponse = await axios.get(`/courses/${universityId}`);
        setCourses(coursesResponse.data);

      } catch (error) {
        console.error('Error fetching users:', error);
      }
    }
  };

  const handleInsertCourse = async (e) => {
    e.preventDefault();
    const CourseName = e.target.elements.CourseName.value;
    const CourseField = e.target.elements.CourseField.value;
    const CourseTopic = e.target.elements.CourseTopic.value;
    console.log(e);
  
    try {
      const lastCourseIdResponse = await fetch(`/last/course/${selectedUniversityId}`, {
        method: 'GET',
      });
  
      if (!lastCourseIdResponse.ok) {
        console.error('Failed to fetch last course internal ID:', lastCourseIdResponse.status);
        return;
      }
  
      const lastCourseIdData = await lastCourseIdResponse.json();
      const lastCourseId = lastCourseIdData.lastCourseId;
      const newCourseId = lastCourseId + 1;
      console.log('last Course Internal ID:', lastCourseId);
  
      const insertCourseResponse = await fetch('/insert/course', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          CourseName,
          CourseField,
          CourseTopic,
          newCourseId: newCourseId,
          universityId: selectedUniversityId,
        }),
      });
  
      if (insertCourseResponse.ok) {        
        fetchCourses(selectedUniversityId);
        e.target.reset();
      } else {
        console.error('Failed to insert Course:', insertCourseResponse.status);
      }
    } catch (error) {
      console.error('Error inserting Course:', error);
    }
  };
  
  
  
  const handleDeleteCourse = async () => {
    try {
      await axios.delete(`/delete/course/${deleteCourseId}`);
      setDeleteCourseId('');
      fetchCourses();
    } catch (error) {
      console.error('Error deleting course:', error);
    }
  };
  
  const handleUpdateCourse = async (event) => {
    event.preventDefault();
  
    try {
      await axios.put(`/update/course/${updateCourseID}/${updateCourseName}/${updateCourseField}/${updateCourseTopic}`);
      setUpdateCourseId('');
      setUpdateCourseName('');
      setUpdateCourseField('');
      setUpdateCourseTopic('');

      fetchCourses();
    } catch (error) {
      console.error('Error updating Course:', error);
    }
  };

  const handleInsertCompany = async (e) => {
    e.preventDefault();
    const CompanyName = e.target.elements.CompanyName.value;
  
    try {
      const lastCompanyIdResponse = await fetch(`/last/company`, {
        method: 'GET',
      });
  
      if (!lastCompanyIdResponse.ok) {
        console.error('Failed to fetch last company ID:', lastCompanyIdResponse.status);
        return;
      }
  
      const lastCompanyIdData = await lastCompanyIdResponse.json();
      const lastCompanyId = lastCompanyIdData.lastCompanyId;
      const newCompanyId = lastCompanyId + 1;
      //console.log('last Company ID:', lastCompanyId);


      const lastCompanyInternalIdResponse = await fetch(`/last/company/internal/${selectedUniversityId}`, {
        method: 'GET',
      });
  
      if (!lastCompanyInternalIdResponse.ok) {
        console.error('Failed to fetch last company ID:', lastCompanyInternalIdResponse.status);
        return;
      }
  
      const lastCompanyInternalIdData = await lastCompanyInternalIdResponse.json();
      const lastCompanyInternalId = lastCompanyInternalIdData.lastInternalCompanyId;
      const newCompanyInternalId = lastCompanyInternalId + 1;
      console.log('last Company Internal ID:', lastCompanyInternalId);
  
      const insertCompanyResponse = await fetch('/insert/company', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          CompanyName,
          newCompanyId: Number(newCompanyId),
          newCompanyInternalId: Number(newCompanyInternalId),
          universityId: Number(selectedUniversityId),
        }),
      });
  
      if (insertCompanyResponse.ok) {        
        fetchCompanies(selectedUniversityId);
        e.target.reset();
      } else {
        console.error('Failed to insert Company:', insertCompanyResponse.status);
      }
    } catch (error) {
      console.error('Error inserting Company:', error);
    }
  };
  
  
  
  const handleDeleteCompany = async () => {
    try {
      await axios.delete(`/delete/company/${deleteCompanyId}`);
      setDeleteCompanyId('');
      fetchCompanies();
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };
  
  const handleUpdateCompany = async (event) => {
    event.preventDefault();
  
    try {
      await axios.put(`/update/company/${updateCompanyId}/${updateCompanyName}`);
      setUpdateCompanyId('');
      setUpdateCompanyName('');
      fetchCompanies();
    } catch (error) {
      console.error('Error updating company:', error);
    }
  };

  const handleCompanyClick = async (companyId) => {
    if (companyId === selectedCompanyId) {
      setSelectedCompanyId('');
      setCompanies([]);      
    } else {
      setSelectedCompanyId(companyId);
       try {
        const jobsResponse = await axios.get(`/jobs/${companyId}`);
        setJobs(jobsResponse.data);
      } catch (error) {
        console.error('Error fetching company jobs:', error);
      } 
    }
  };

  //Job
  const handleInsertJob = async (e) => {
    e.preventDefault();
    const JobName = e.target.elements.jobName.value;
  
    try {
      const lastJobIdResponse = await fetch(`/last/job/${selectedCompanyId}`, {
        method: 'GET',
      });
  
      if (!lastJobIdResponse.ok) {
        console.error('Failed to fetch last job ID:', lastJobIdResponse.status);
        return;
      }
  
      const lastJobIdData = await lastJobIdResponse.json();
      const lastJobId = lastJobIdData.lastJobId;
      const newJobId = lastJobId + 1;
      //console.log('last Job ID:', lastJobId);


      const lastCompanyInternalIdResponse = await fetch(`/last/job/internal/${selectedUniversityId}/${selectedCompanyId}`, {
        method: 'GET',
      });
  
      if (!lastCompanyInternalIdResponse.ok) {
        console.error('Failed to fetch last Company internal ID:', lastCompanyInternalIdResponse.status);
        return;
      }
  
      const lastCompanyInternalIdData = await lastCompanyInternalIdResponse.json();
      const lastCompanyInternalId = lastCompanyInternalIdData.lastInternalCompanyId;
      const newCompanyInternalId = lastCompanyInternalId + 1;
      console.log('last Company Internal ID:', lastCompanyInternalId);
  
      const insertJobResponse = await fetch('/insert/job', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          JobName,
          universityId: Number(selectedUniversityId),
          companyId: Number(selectedCompanyId),
          newJobId: Number(newJobId),
          newCompanyInternalId: Number(newCompanyInternalId),          
        }),
      });  
  
      if (insertJobResponse.ok) {        
        fetchJobs(selectedCompanyId);
        e.target.reset();
      } else {
        console.error('Failed to insert Job:', insertJobResponse.status);
      }
    } catch (error) {
      console.error('Error inserting Job:', error);
    }
  };
  
  
  
  const handleDeleteJob = async () => {
    try {
      await axios.delete(`/delete/job/${deleteJobId}`);
      setDeleteJobId('');
      fetchJobs();
    } catch (error) {
      console.error('Error deleting job:', error);
    }
  };
  
  const handleUpdateJob = async (event) => {
    event.preventDefault();
  
    try {
      await axios.put(`/update/job/${updateJobId}/${updateJobName}`);
      setUpdateJobId('');
      setUpdateJobName('');
      fetchJobs();
    } catch (error) {
      console.error('Error updating Job:', error);
    }
  };

  const handleJobClick = async (jobId) => {
    if (jobId === selectedJobId) {
      setSelectedJobId('');
      setJobCertificates([]);      
    } else {
      setSelectedJobId(jobId);
      try {
        const jobCertificatesResponse = await axios.get(`/jobCertificates/${jobId}`);
        setJobCertificates(jobCertificatesResponse.data);

      } catch (error) {
        console.error('Error jobs/job certificates:', error);
      } 
    }
  };

  const handleInsertJobCertificate = async (e) => {
    e.preventDefault();
    const JobId = selectedJobId;
    const CertificateName = e.target.elements.CertificateName.value;
    const CertificateCourseID = e.target.elements.CertificateCourseID.value;
    const CertificateCourseName = e.target.elements.CertificateCourseName.value;
  
    try { 
  
      const insertJobCertificateResponse = await fetch('/insert/jobCertificates', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          JobId,
          CertificateName,
          CertificateCourseID,
          CertificateCourseName,
        }),
      });  
  
      if (insertJobCertificateResponse.ok) {        
        fetchJobCertificates(selectedJobId);
        e.target.reset();
      } else {
        console.error('Failed to insert Job Certificate:', insertJobCertificateResponse.status);
      }
    } catch (error) {
      console.error('Error inserting Job Certificate:', error);
    }
  };




  const handleDeleteJobCertificate = async () => {
    try {
      await axios.delete(`/delete/jobCertificates/${deleteJobCertificateId}`);
      setDeleteJobCertificateId('');
      fetchJobCertificates(selectedJobId);
    } catch (error) {
      console.error('Error deleting Job Certificate:', error);
    }
  };

  const handleUpdateJobCertificate = async (event) => {
    event.preventDefault();

    try {
      await axios.put(`/update/jobCertificates/${updateJobCertificateId}/${updateJobCertificateName}/${updateJobCertificateCourseID}/${updateJobCertificateCourseName}`);

      setUpdateJobCertificateId('');
      setUpdateJobCertificateName('');
      setUpdateJobCertificateCourseID('');
      setUpdateJobCertificateCourseName('');
      fetchJobCertificates(selectedJobId);

    } catch (error) {
      console.error('Error updating Job Certificates:', error);
    }
  };


  const handleInsertUser = async (e) => {
    e.preventDefault();
    const userName = e.target.elements.userName.value;
  
    try {
      const lastUserIdResponse = await fetch(`/last/user/${selectedUniversityId}`, {
        method: 'GET',
      });
  
      if (!lastUserIdResponse.ok) {
        console.error('Failed to fetch last user ID:', lastUserIdResponse.status);
        return;
      }
  
      const lastUserIdData = await lastUserIdResponse.json();
      const lastUserId = lastUserIdData.lastUserId;
      const newUserId = lastUserId + 1;
      console.log('last user ID:', lastUserId);
  
      const insertUserResponse = await fetch('/insert/user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userName,
          newUserId: newUserId,
          universityId: selectedUniversityId,
        }),
      });
  
      if (insertUserResponse.ok) {
        // User inserted successfully, update the user list
        fetchUsers(selectedUniversityId);
        e.target.reset();
      } else {
        console.error('Failed to insert user:', insertUserResponse.status);
      }
    } catch (error) {
      console.error('Error inserting user:', error);
    }
  };
  
  
  
  const handleDeleteUser = async () => {
    try {
      await axios.delete(`/delete/user/${deleteUserId}`);
      setDeleteUserId('');
      fetchUsers(selectedUniversityId);
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };
  
  const handleUpdateUser = async (event) => {
    event.preventDefault();
  
    try {
      await axios.put(`/update/user/${updateUserId}`, { userName: updateUserName });
      setUpdateUserId('');
      setUpdateUserName('');
      fetchUsers(selectedUniversityId);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  const handleUserClick = async (userId) => {
    if (userId === selectedUserId) {
      setSelectedUserId('');
      setCertificates([]);
      setProfessionalCertificates([]);
    } else {
      setSelectedUserId(userId);
      try {
        const certificatesResponse = await axios.get(`/certificates/${userId}`);
        setCertificates(certificatesResponse.data);
        const professionalcertificatesResponse = await axios.get(`/professionalcertificates/${userId}`);
        setProfessionalCertificates(professionalcertificatesResponse.data);

      } catch (error) {
        console.error('Error fetching certificates/pf_certificates:', error);
      }
    }
  };


  const handleInsertCertificate = async (event) => {
    event.preventDefault();
    const courseId = event.target.elements.courseId.value;
    const grade = event.target.elements.grade.value;
    const date = event.target.elements.date.value;
  
    try {
      const lastCertificateIdResponse = await fetch(`/last/certificate/${selectedUniversityId}`, {
        method: 'GET',
      });
  
      if (!lastCertificateIdResponse.ok) {
        console.error('Failed to fetch last certificate ID:', lastCertificateIdResponse.status);
        return;
      }
  
      const lastCertificateIdData = await lastCertificateIdResponse.json();
      const lastCertificateId = lastCertificateIdData.lastCertificateId;
      const newCertificateId = lastCertificateId + 1;
      console.log('last certificate ID:', lastCertificateId);
  
      const insertCertificateResponse = await fetch('/insert/certificate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          universityId: selectedUniversityId,
          courseId,
          grade,
          date,
          certificateId: newCertificateId,
          userId: selectedUserId,
        }),
      });
  
      if (insertCertificateResponse.ok) {
        // Certificate inserted successfully, update the certificate list
        fetchCertificates(selectedUserId);
        event.target.reset();
      } else {
        console.error('Failed to insert certificate:', insertCertificateResponse.status);
      }
    } catch (error) {
      console.error('Error inserting certificate:', error);
    }
  }; 

  const handleDeleteCertificate = async () => {
    try {
      await axios.delete(`/delete/certificate/${deleteCertificateId}`);
      setDeleteCertificateId('');
      fetchCertificates(selectedUserId);
    } catch (error) {
      console.error('Error deleting certificate:', error);
    }
  };

  const handleUpdateCertificate = async (event) => {
    event.preventDefault();
  
    try {
      await axios.put(`/update/certificate/${updateCertificateId}`, {
        courseId: updateCourseId,
        grade: updateGrade,
        date: updateDate,
        userId: selectedUserId,
        universityId: selectedUniversityId,
        id: selectedCertificateId,
      });
      setUpdateCertificateId('');
      setUpdateCourseId('');
      setUpdateGrade('');
      setUpdateDate('');
      fetchCertificates(selectedUserId);
    } catch (error) {
      console.error('Error updating certificate:', error);
    }
  };
  
  const handleCertificateClick = async (selectedUniversityId, certificateId) => {
    if (certificateId === selectedCertificateId) {
      setSelectedCertificateId('');
    } else {
      setSelectedCertificateId(certificateId);
      try {
        // Fetch equivalences for the selected certificate       
        const equivalencesResponse = await axios.get(`/equivalences/${selectedUniversityId}/${certificateId}`);
        // Store the equivalences data in a state variable
        setEquivalences(equivalencesResponse.data);
      } catch (error) {
        console.error('Error fetching equivalences:', error);
      }
    }
  };


const handleInsertEquivalence = async (event) => {
  event.preventDefault();
  
  //const sourceUniversityId = event.target.elements.sourceUniversityId.value;
  //const sourceCertificate = event.target.elements.sourceCertificate.value;
  const targetUniversityId = event.target.elements.targetUniversityId.value;
  const targetCourseId = event.target.elements.targetCourseId.value;
  const date = event.target.elements.date.value;

  try {
    const lastEquivalenceIdResponse = await fetch(`/last/equivalence`, {
      method: 'GET',
    });

    if (!lastEquivalenceIdResponse.ok) {
      console.error('Failed to fetch last equivalence ID:', lastEquivalenceIdResponse.status);
      return;
    }

    const lastEquivalenceIdData = await lastEquivalenceIdResponse.json();
    const lastEquivalenceId = lastEquivalenceIdData.lastEquivalenceId;
    const newEquivalenceId = lastEquivalenceId + 1;
    console.log('last equivalence ID:', lastEquivalenceId);

    const insertEquivalenceResponse = await fetch('/insert/equivalence', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({        
        sourceUniversityId: selectedUniversityId,
        sourceCertificate: selectedCertificateId,
        targetUniversityId,
        targetCourseId,
        date,
        equivalenceId: newEquivalenceId,
      }),
    });

    if (insertEquivalenceResponse.ok) {
      // Equivalence inserted successfully, update the equivalence list
      fetchEquivalences(selectedUniversityId, selectedCertificateId);

      //BLOCKCHAIN CALL
      //handleCertificationVerification('academic');

      event.target.reset();
    } else {
      console.error('Failed to insert equivalence:', insertEquivalenceResponse.status);
    }
  } catch (error) {
    console.error('Error inserting equivalence:', error);
  }
};

const handleDeleteEquivalence = async () => {
  try {
    await axios.delete(`/delete/equivalence/${deleteEquivalenceId}`);
    setDeleteEquivalenceId('');
    fetchEquivalences(selectedUniversityId, selectedCertificateId);
  } catch (error) {
    console.error('Error deleting equivalence:', error);
  }
};

const handleUpdateEquivalence = async (event) => {
  event.preventDefault();
  try {
    await axios.put(`/update/equivalence/${updateEquivalenceId}`, {
      sourceUniversityId: selectedUniversityId,
      sourceCertificate: selectedCertificateId,
      targetUniversityId: updateEquivalenceTargetUni,
      targetCourseId: updateEquivalenceTargetCourse,
      date: updateEquivalenceDate,
      equivalenceId: updateEquivalenceId,
    });

    setUpdateEquivalenceDate('');
    setUpdateEquivalenceTargetCourse('');
    setUpdateEquivalenceTargetUni('');    

    fetchEquivalences(selectedUniversityId, selectedCertificateId);
  } catch (error) {
    console.error('Error updating equivalence:', error);
  }
};

const handleInsertProfessionalCertificate = async (event) => {
  event.preventDefault();
  const jobCertificateId = event.target.elements.jobCertificateId.value;
  const courseId = event.target.elements.courseId.value;
  const grade = event.target.elements.grade.value;
  const date = event.target.elements.date.value;

  try {
    const lastCertificateIdResponse = await fetch(`/last/professionalcertificates/${selectedUniversityId}`, {
      method: 'GET',
    });

    if (!lastCertificateIdResponse.ok) {
      console.error('Failed to fetch last professional certificate ID:', lastCertificateIdResponse.status);
      return;
    }

    const lastCertificateIdData = await lastCertificateIdResponse.json();
    const lastCertificateId = lastCertificateIdData.lastCertificateId;
    const newCertificateId = lastCertificateId + 1;
    console.log('last professional certificate ID:', lastCertificateId);

    const insertCertificateResponse = await fetch('/insert/professionalcertificates', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        universityId: selectedUniversityId,
        courseId,
        grade,
        date,
        jobCertificateId,
        certificateId: newCertificateId,
        userId: selectedUserId,
      }),
    });

    if (insertCertificateResponse.ok) {
      // Certificate inserted successfully, update the certificate list
      fetchProfessionalCertificates(selectedUserId);
      event.target.reset();
    } else {
      console.error('Failed to insert certificate:', insertCertificateResponse.status);
    }
  } catch (error) {
    console.error('Error inserting certificate:', error);
  }
}; 

const handleDeleteProfessionalCertificate = async () => {
  try {
    await axios.delete(`/delete/professionalcertificates/${deleteProfessionalCertificateId}`);
    setDeleteProfessionalCertificateId('');
    fetchProfessionalCertificates(selectedUserId);
  } catch (error) {
    console.error('Error deleting professional certificate:', error);
  }
};

const handleUpdateProfessionalCertificate = async (event) => {
  event.preventDefault();
  try {    
    await axios.put(`/update/professionalcertificates/${updateProfessionalCertificateId}`, {
      courseId: updateProfessionalCourseId,
      grade: updateProfessionalGrade,
      date: updateProfessionalDate,
    });

    setUpdateProfessionalCertificateId('');
    setUpdateProfessionalCourseId('');
    setUpdateProfessionalGrade('');
    setUpdateProfessionalDate('');

    fetchProfessionalCertificates(selectedUserId);
  } catch (error) {
    console.error('Error updating certificate:', error);
  }
};

const handleProfessionalCertificateClick = async (selectedUniversityId, professionalcertificateId) => {
  if (professionalcertificateId === selectedProfessionalCertificateId) {
    setSelectedProfessionalCertificateId('');
  } else {
    setSelectedProfessionalCertificateId(professionalcertificateId);
    try {
      // Fetch equivalences for the selected certificate       
      const professionalequivalencesResponse = await axios.get(`/professionalequivalences/${selectedUniversityId}/${professionalcertificateId}`);
      // Store the equivalences data in a state variable
      setProfessionalEquivalences(professionalequivalencesResponse.data);
    } catch (error) {
      console.error('Error fetching professional equivalences:', error);
    }
  }
};

const handleInsertProfessionalEquivalence = async (event) => {
  event.preventDefault();
  
  //const sourceUniversityId = event.target.elements.sourceUniversityId.value;
  //const sourceCertificate = event.target.elements.sourceCertificate.value;
  const targetUniversityId = event.target.elements.targetUniversityId.value;
  const targetCourseId = event.target.elements.targetCourseId.value;
  const date = event.target.elements.date.value;

  try {   
    const insertEquivalenceResponse = await fetch('/insert/professionalequivalences', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({        
        sourceUniversityId: selectedUniversityId,
        sourceCertificate: selectedProfessionalCertificateId,
        targetUniversityId,
        targetCourseId,
        date,
        //equivalenceId: newEquivalenceId,
      }),
    });

    if (insertEquivalenceResponse.ok) {
      // Equivalence inserted successfully, update the equivalence list
      fetchProfessionalEquivalences(selectedUniversityId, selectedProfessionalCertificateId);

      //BLOCKCHAIN CALL
      //handleCertificationVerification('professional');

      event.target.reset();
    } else {
      console.error('Failed to insert equivalence:', insertEquivalenceResponse.status);
    }
  } catch (error) {
    console.error('Error inserting equivalence:', error);
  }
};

const handleDeleteProfessionalEquivalence = async () => {
  try {
    await axios.delete(`/delete/professionalequivalences/${deleteProfessionalEquivalenceId}`);
    setDeleteProfessionalEquivalenceId('');
    fetchProfessionalEquivalences(selectedUniversityId, selectedProfessionalCertificateId);
  } catch (error) {
    console.error('Error deleting professional equivalence:', error);
  }
};

const handleUpdateProfessionalEquivalence = async (event) => {
  event.preventDefault();
  try {
    await axios.put(`/update/professionalequivalences/${updateProfessionalEquivalenceId}`, {      
      targetUniversityId: updateProfessionalEquivalenceTargetUni,
      targetCourseId: updateProfessionalEquivalenceTargetCourse,
      date: updateProfessionalEquivalenceDate,
      //equivalenceId: updateProfessionalEquivalenceId,
    });

    setUpdateProfessionalEquivalenceDate('');
    setUpdateProfessionalEquivalenceTargetCourse('');
    setUpdateProfessionalEquivalenceTargetUni('');    

    fetchProfessionalEquivalences(selectedUniversityId, selectedProfessionalCertificateId);
  } catch (error) {
    console.error('Error updating Professional equivalence:', error);
  }
};


  return (
    <div className="container">
      <button className={`buttontab ${selectionTab === 3 ? 'active' : ''}`} onClick={() => setSelectionTab(3)}>Local University</button>
      <button className={`buttontab ${selectionTab === 1 ? 'active' : ''}`} onClick={() => setSelectionTab(1)}>Portal Simulator</button>   
      <button className={`buttontab ${selectionTab === 2 ? 'active' : ''}`} onClick={() => setSelectionTab(2)}>Database Management</button> 
      <div className="opening_container">         
        <div className="header">
          <div className="title-container">
          {selectionTab === 1 ? <h1 className="title">YachaY Portal Simulator</h1> : null}
          {selectionTab === 2 ? <h1 className="title">YachaY Database Manager</h1> : null}
          {selectionTab === 3 ? <h1 className="title">Local University System </h1> : null}         
          </div>
          <div className="image-container">
            <img src={logo} alt="Logo" className="logo" />
          </div>
        </div>
      </div>

      {selectionTab === 3 && (<UniversityLocal_Certification/>)} 

      {selectionTab === 2 && (<Login fetchAccessLevel={fetchAccessLevel} /> )}     
      
      {selectionTab === 2 && accessLevel === 'admin' && (
        <div className="universities-section">
        <h2>Universities:</h2>
        <div className="universities-actions">
          <form className="form" onSubmit={handleInsert}>
            <input type="text" name="universityName" placeholder="University Name" />
            <button type="submit">Insert</button>
          </form>
          <form className="form">
            <input
              type="number"
              value={deleteId}
              onChange={(e) => setDeleteId(e.target.value)}
              placeholder="University ID"
            />
            <button type="button" className="btn-delete" onClick={handleDelete}>
              Delete
            </button>
          </form>
          <form className="form" onSubmit={handleUpdate}>
            <input
              type="number"
              value={updateId}
              onChange={(e) => setUpdateId(e.target.value)}
              placeholder="University ID"
            />
            <input
              type="text"
              value={updateName}
              onChange={(e) => setUpdateName(e.target.value)}
              placeholder="New University Name"
            />
            <button type="submit" className="btn-update">
              Update
            </button>
          </form>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>University Name</th>
            </tr>
          </thead>
          <tbody>
            {universities.map((university) => (
              <tr
                key={university.ID_YachaY_University}
                onClick={() => handleUniversityClick(university.ID_YachaY_University)}
                className={selectedUniversityId === university.ID_YachaY_University ? 'selected' : ''}
              >
                <td>{university.ID_YachaY_University}</td>
                <td>{university.Name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      )}    

      {selectionTab === 2 && (accessLevel === 'admin' || accessLevel === 'university'  ) && (
        <div> 
          <button className={`buttontab ${activeTab === 3 ? 'active' : ''}`} onClick={() => setActiveTab(3)}>Courses</button>   
          <button className={`buttontab ${activeTab === 1 ? 'active' : ''}`} onClick={() => setActiveTab(1)}>Certification</button>   
          <button className={`buttontab ${activeTab === 2 ? 'active' : ''}`} onClick={() => setActiveTab(2)}>Companies</button>        
        </div>)}

      {selectionTab === 2 && activeTab === 1 ? <div>
        {selectedUniversityId && (
        <div>
          <hr className="divider" />
          <div className="users-section">
            <h2>Users:</h2>
            <div className="users-actions">
            <form className="form" onSubmit={handleInsertUser}>
              <input type="text" name="userName" placeholder="User Name" />              
              <button type="submit">Insert</button>
            </form>
              <form className="form">
                <input
                  type="number"
                  value={deleteUserId}
                  onChange={(e) => setDeleteUserId(e.target.value)}
                  placeholder="User ID"
                />
                <button type="button" className="btn-delete" onClick={handleDeleteUser}>
                  Delete
                </button>
              </form>
              <form className="form" onSubmit={handleUpdateUser}>
                <input
                  type="number"
                  value={updateUserId}
                  onChange={(e) => setUpdateUserId(e.target.value)}
                  placeholder="User ID"
                />
                <input
                  type="text"
                  value={updateUserName}
                  onChange={(e) => setUpdateUserName(e.target.value)}f
                  placeholder="New User Name"
                />
                <button type="submit" className="btn-update">
                  Update
                </button>
              </form>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th>YachaY ID</th>
                  <th>Internal ID</th>
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user) => (
                  <tr
                    key={user.ID_YachaY_User}
                    onClick={() => handleUserClick(user.ID_YachaY_User)}
                    className={selectedUserId === user.ID_YachaY_User ? 'selected' : ''}
                  >
                    <td>{user.ID_YachaY_User}</td>
                    <td>{user.Internal_User_ID}</td>
                    <td>{user.Name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

        </div>
      )}

      {selectionTab === 2 && selectedUserId && (
        <div>
          <hr className="divider" />
          <h2>Academic Certificates:</h2>
          <div className="certificates-actions">
            <form className="form" onSubmit={handleInsertCertificate}>
              <input type="number" name="courseId" placeholder="Course ID" />
              <input type="number" name="grade" placeholder="Grade" />
              <input type="date" name="date" placeholder="Date" />
              <button type="submit">Insert</button>
            </form>
            <form className="form">
              <input
                type="number"
                value={deleteCertificateId}
                onChange={(e) => setDeleteCertificateId(e.target.value)}
                placeholder="Certificate ID"
              />
              <button type="button" className="btn-delete" onClick={handleDeleteCertificate}>
                Delete
              </button>
            </form>
            <form className="form" onSubmit={handleUpdateCertificate}>              
              <input
                type="number"
                value={updateCertificateId}
                onChange={(e) => setUpdateCertificateId(e.target.value)}
                placeholder="Certificate ID"
              />
              <input
                type="number"
                value={updateCourseId}
                onChange={(e) => setUpdateCourseId(e.target.value)}
                placeholder="New Course ID"
              />
              <input
                type="number"
                value={updateGrade}
                onChange={(e) => setUpdateGrade(e.target.value)}
                placeholder="New Grade"
              />
              <input
                type="date"
                value={updateDate}
                onChange={(e) => setUpdateDate(e.target.value)}
                placeholder="New Date"
              />
              <button type="submit" className="btn-update">
                Update
              </button>
            </form>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th>Certificate ID</th>
                <th>Internal ID</th>
                <th>Course ID</th>
                <th>Course Name</th>
                <th>Grade</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {certificates.map((certificate) => (
                <tr
                  key={certificate.ID_YachaY_Certificate}
                  onClick={() => handleCertificateClick(selectedUniversityId, certificate.ID_YachaY_Certificate)}
                  className={selectedCertificateId === certificate.ID_YachaY_Certificate ? 'selected' : ''}
                >
                  <td>{certificate.ID_YachaY_Certificate}</td>
                  <td>{certificate.ID_Internal_Certificate}</td>
                  <td>{certificate.ID_YachaY_Course}</td>     
                  <td>{certificate.associatedCourseName}</td>            
                  <td>{certificate.Grade}</td>
                  <td>{certificate.Date}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      
      {selectionTab === 2 && selectedCertificateId && (
        <div>          
          <h2>Academic Equivalences:</h2>
          <div className="equivalences-actions">
            <form className="form" onSubmit={handleInsertEquivalence}>         
              <input type="number" name="targetUniversityId" placeholder="Target University ID" />
              <input type="number" name="targetCourseId" placeholder="Target Course ID" />
              <input type="date" name="date" placeholder="Date" />
              <button type="submit">Insert</button>
            </form>
            <form className="form">
              <input
                type="number"
                value={deleteEquivalenceId}
                onChange={(e) => setDeleteEquivalenceId(e.target.value)}
                placeholder="Equivalence ID"
              />
              <button type="button" className="btn-delete" onClick={handleDeleteEquivalence}>
                Delete
              </button>
            </form>
            <form className="form" onSubmit={handleUpdateEquivalence}>
              <input
                type="number"
                value={updateEquivalenceId}
                onChange={(e) => setUpdateEquivalenceId(e.target.value)}
                placeholder="Equivalence ID"
              />
              <input
                type="number"
                value={updateEquivalenceTargetUni}
                onChange={(e) => setUpdateEquivalenceTargetUni(e.target.value)}
                placeholder="Target Uni ID"
              />
              <input
                type="number"
                value={updateEquivalenceTargetCourse}
                onChange={(e) => setUpdateEquivalenceTargetCourse(e.target.value)}
                placeholder="Target Course ID"
              />
              <input
                type="date"
                value={updateEquivalenceDate}
                onChange={(e) => setUpdateEquivalenceDate(e.target.value)}
                placeholder="Date"
              />
              <button type="submit" className="btn-update">
                Update
              </button>
            </form>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th>Equivalence ID</th>                
                <th>Source UNI ID</th>
                <th>Source Name</th>
                <th>Source CTF ID</th>
                <th>Source Course ID</th>
                <th>Source Course Name</th>
                <th>Target UNI ID</th>
                <th>Target Name</th>
                <th>Target Course ID</th>
                <th>Target Course Name</th>
                <th>Date</th>
              </tr>
            </thead>
            {equivalences && Array.isArray(equivalences) && (
              <tbody>
                {equivalences.map((equivalence) => (
                  <tr key={equivalence.ID_YachaY_Equivalence}>
                    <td>{equivalence.ID_YachaY_Equivalence}</td>
                    <td>{equivalence.Source_ID_YachaY_University}</td>
                    <td>{equivalence.associatedSourceName}</td>
                    <td>{equivalence.Source_Internal_Certificate}</td>
                    <td>{equivalence.associatedSourceCourseID}</td>
                    <td>{equivalence.associatedSourceCourseName}</td>
                    <td>{equivalence.Target_ID_YachaY_University}</td>
                    <td>{equivalence.associatedTargetName}</td>   
                    <td>{equivalence.Target_Internal_Course_ID}</td>
                    <td>{equivalence.associatedTargetCourseName}</td>              
                    <td>{equivalence.Date}</td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      )}

{selectionTab === 2 && selectedUserId && (
  <div>
    <hr className="divider" />
    <h2>Professional Certificates:</h2>
    <div className="table-actions">
      <div className="line">
        <form className="form" onSubmit={handleInsertProfessionalCertificate}>
          <input
            type="number"
            name="jobCertificateId"
            placeholder="Job Certificate ID"
          />
          <input
            type="number"
            name="courseId"
            placeholder="Course ID"
          />
          <input
            type="number"
            name="grade"
            placeholder="Grade"
          />
          <input
            type="date"
            name="date"
            placeholder="Date"
          />
          <button type="submit">Insert</button>
        </form>
      </div>
      <div className="line">
        <form className="form">
          <input
            type="number"
            value={deleteProfessionalCertificateId}
            onChange={(e) => setDeleteProfessionalCertificateId(e.target.value)}
            placeholder="Certificate ID"
          />
          <button
            type="button"
            className="btn-delete"
            onClick={handleDeleteProfessionalCertificate}
          >
            Delete
          </button>
        </form>
      </div>
      <div className="line">
        <form className="form" onSubmit={handleUpdateProfessionalCertificate}>
          <input
            type="number"
            value={updateProfessionalCertificateId}
            onChange={(e) => setUpdateProfessionalCertificateId(e.target.value)}
            placeholder="Certificate ID"
          />
          <input
            type="number"
            value={updateProfessionalCourseId}
            onChange={(e) => setUpdateProfessionalCourseId(e.target.value)}
            placeholder="New Course ID"
          />
          <input
            type="number"
            value={updateProfessionalGrade}
            onChange={(e) => setUpdateProfessionalGrade(e.target.value)}
            placeholder="New Grade"
          />
          <input
            type="date"
            value={updateProfessionalDate}
            onChange={(e) => setUpdateProfessionalDate(e.target.value)}
            placeholder="New Date"
          />
          <button type="submit" className="btn-update">
            Update
          </button>
        </form>
      </div>
    </div>
    <table className="table">
      <thead>
        <tr>
          <th>PF Certificate ID</th>
          <th>Job Certificate</th>
          <th>Job Certf Name</th>
          <th>Course ID</th>
          <th>Course Name</th>
          <th>Grade</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        {professionalCertificates.map((professionalCertificate) => (
          <tr
            key={professionalCertificate.ID_YachaY_Professional_Certificate}
            onClick={() =>
              handleProfessionalCertificateClick(
                selectedUniversityId,
                professionalCertificate.ID_YachaY_Professional_Certificate
              )
            }
            className={
              selectedProfessionalCertificateId === professionalCertificate.ID_YachaY_Professional_Certificate ? 'selected' : ''}>
            <td>{professionalCertificate.ID_YachaY_Professional_Certificate}</td>
            <td>{professionalCertificate.ID_YachaY_Job_Certificate}</td>
            <td>{professionalCertificate.associatedJobCertificateName}</td>
            <td>{professionalCertificate.ID_YachaY_Course}</td>
            <td>{professionalCertificate.associatedCourseName}</td>
            <td>{professionalCertificate.Grade}</td>
            <td>{professionalCertificate.Date}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)}

{selectionTab === 2 && selectedProfessionalCertificateId && (
  <div>
    <h2>Professional Equivalences:</h2>
    <div className="table-actions">
      <div className="line">
        <form className="form" onSubmit={handleInsertProfessionalEquivalence}>
          <input
            type="number"
            name="targetUniversityId"
            placeholder="Target University ID"
          />
          <input
            type="number"
            name="targetCourseId"
            placeholder="Target Course ID"
          />
          <input type="date" name="date" placeholder="Date" />
          <button type="submit">Insert</button>
        </form>
      </div>
      <div className="line">
        <form className="form">
          <input
            type="number"
            value={deleteProfessionalEquivalenceId}
            onChange={(e) => setDeleteProfessionalEquivalenceId(e.target.value)}
            placeholder="Equivalence ID"
          />
          <button
            type="button"
            className="btn-delete"
            onClick={handleDeleteProfessionalEquivalence}
          >
            Delete
          </button>
        </form>
      </div>
      <div className="line">
        <form className="form" onSubmit={handleUpdateProfessionalEquivalence}>
          <input
            type="number"
            value={updateProfessionalEquivalenceId}
            onChange={(e) => setUpdateProfessionalEquivalenceId(e.target.value)}
            placeholder="Equivalence ID"
          />
          <input
            type="number"
            value={updateProfessionalEquivalenceTargetUni}
            onChange={(e) =>
              setUpdateProfessionalEquivalenceTargetUni(e.target.value)
            }
            placeholder="Target Uni ID"
          />
          <input
            type="number"
            value={updateProfessionalEquivalenceTargetCourse}
            onChange={(e) =>
              setUpdateProfessionalEquivalenceTargetCourse(e.target.value)
            }
            placeholder="Target Course ID"
          />
          <input
            type="date"
            value={updateProfessionalEquivalenceDate}
            onChange={(e) =>
              setUpdateProfessionalEquivalenceDate(e.target.value)
            }
            placeholder="Date"
          />
          <button type="submit" className="btn-update">
            Update
          </button>
        </form>
      </div>
    </div>    
    <table className="table">    
      <thead>
        <tr>
          <th>Equivalence ID</th>          
          <th>Source UNI ID</th>
          <th>Source Name</th>
          <th>Source CTF ID</th>
          <th>Source Course Name</th>
          <th>Target UNI ID</th>
          <th>Target Name</th>
          <th>Target INT Course ID</th>
          <th>Target Course Name</th>
          <th>Date</th>
        </tr>
      </thead>
      {professionalEquivalences &&
        Array.isArray(professionalEquivalences) && (
          <tbody>
            {professionalEquivalences.map((professionalEquivalence) => (
              <tr key={professionalEquivalence.ID_YachaY_Professional_Equivalence}>
                <td>{professionalEquivalence.ID_YachaY_Professional_Equivalence}</td>               
                <td>{professionalEquivalence.Source_ID_YachaY_University}</td>
                <td>{professionalEquivalence.associatedSourceName}</td>
                <td>{professionalEquivalence.Source_Internal_Certificate}</td>
                <td>{professionalEquivalence.associatedSourceCourseName}</td>
                <td>{professionalEquivalence.Target_ID_YachaY_University}</td>
                <td>{professionalEquivalence.associatedTargetName}</td>
                <td>{professionalEquivalence.Target_Internal_Course_ID}</td>
                <td>{professionalEquivalence.associatedTargetCourseName}</td>
                <td>{professionalEquivalence.Date}</td>
              </tr>
            ))}
          </tbody>
        )}
    </table>
  </div>
)} </div>:  <div></div>}




  {selectionTab === 2 && activeTab === 2 ? <div>
        {selectedUniversityId && (
    <div>
      <hr className="divider" />
      <div className="companies-section">
        <h2>Associated Companies:</h2>
        <div className="companies-actions">
        <form className="form" onSubmit={handleInsertCompany}>
          <input type="text" name="CompanyName" placeholder="Company Name" />              
          <button type="submit">Insert</button>
        </form>
          <form className="form">
            <input
              type="number"
              value={deleteCompanyId}
              onChange={(e) => setDeleteCompanyId(e.target.value)}
              placeholder="Company ID"
            />
            <button type="button" className="btn-delete" onClick={handleDeleteCompany}>
              Delete
            </button>
          </form>
          <form className="form" onSubmit={handleUpdateCompany}>
            <input
              type="number"
              value={updateCompanyId}
              onChange={(e) => setUpdateCompanyId(e.target.value)}
              placeholder="Company ID"
            />
            <input
              type="text"
              value={updateCompanyName}
              onChange={(e) => setUpdateCompanyName(e.target.value)}f
              placeholder="New Company Name"
            />
            <button type="submit" className="btn-update">
              Update
            </button>
          </form>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th>Company ID</th>   
              <th>Internal ID</th>           
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {companies.map((job) => (
              <tr
                key={job.ID_YachaY_Company}
                onClick={() => handleCompanyClick(job.ID_YachaY_Company)}
                className={selectedCompanyId === job.ID_YachaY_Company ? 'selected' : ''}
              >
                <td>{job.ID_YachaY_Company}</td>                     
                <td>{job.ID_Internal_Company}</td>             
                <td>{job.Name}</td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>

    </div>
  )}

{selectionTab === 2 && selectedCompanyId && (
        <div>          
          <h2>Company Jobs:</h2>
          <div className="jobs-actions">
            <form className="form" onSubmit={handleInsertJob}>        
              <input type="text" name="jobName" placeholder="Job Name" />              
              <button type="submit">Insert</button>
            </form>
            <form className="form">
              <input
                type="number"
                value={deleteJobId}
                onChange={(e) => setDeleteJobId(e.target.value)}
                placeholder="Job ID"
              />
              <button type="button" className="btn-delete" onClick={handleDeleteJob}>
                Delete
              </button>
            </form>
            <form className="form" onSubmit={handleUpdateJob}>
            <input
              type="number"
              value={updateJobId}
              onChange={(e) => setUpdateJobId(e.target.value)}
              placeholder="Job ID"
            />
            <input
              type="text"
              value={updateJobName}
              onChange={(e) => setUpdateJobName(e.target.value)}
              placeholder="New Job Name"
            />
            <button type="submit" className="btn-update">
              Update
            </button>
          </form>

           
          </div>
          <table className="table">
            <thead>
              <tr>
                <th>Job ID</th>                
                <th>Name</th>               
              </tr>
            </thead>
            {jobs && Array.isArray(jobs) && (
              <tbody>
                {jobs.map((job) => (
                  <tr key={job.ID_YachaY_Job}
                  onClick={() => handleJobClick(job.ID_YachaY_Job)}
                  className={selectedJobId === job.ID_YachaY_Job ? 'selected' : ''}
                  >                  
                    <td>{job.ID_YachaY_Job}</td>                    
                    <td>{job.Name}</td>              
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      )}

{selectionTab === 2 && selectedJobId && (
        <div>          
          <h2>Job Certificates:</h2>
          <div className="jobcertificates-actions">
            <form className="form" onSubmit={handleInsertJobCertificate}>        
              <input type="text" name="CertificateName" placeholder="Certificate Name" />
              <input type="text" name="CertificateCourseID" placeholder="Course ID" />
              <input type="text" name="CertificateCourseName" placeholder="Course Name" />
              <button type="submit">Insert</button>
            </form>
            <form className="form">
              <input
                type="number"
                value={deleteJobCertificateId}
                onChange={(e) => setDeleteJobCertificateId(e.target.value)}
                placeholder="Job Certificate ID"
              />
              <button type="button" className="btn-delete" onClick={handleDeleteJobCertificate}>
                Delete
              </button>
            </form>     
            <form className="form" onSubmit={handleUpdateJobCertificate}>
              <input
                type="number"
                value={updateJobCertificateId}
                onChange={(e) => setUpdateJobCertificateId(e.target.value)}
                placeholder="Job Certificate ID"
              />
              <input
                type="text"
                value={updateJobCertificateName}
                onChange={(e) => setUpdateJobCertificateName(e.target.value)}
                placeholder="Name"
              />
                <input
                type="number"
                value={updateJobCertificateCourseID}
                onChange={(e) => setUpdateJobCertificateCourseID(e.target.value)}
                placeholder="Course ID"
              />
                <input
                type="text"
                value={updateJobCertificateCourseName}
                onChange={(e) => setUpdateJobCertificateCourseName(e.target.value)}
                placeholder="Course Name"
              />
              <button type="submit" className="btn-update">
                Update
              </button>
            </form>

           
          </div>
          <table className="table">
            <thead>
              <tr>
                <th>Job Certificate ID</th>
                <th>Job ID</th>
                <th>Name</th>  
                <th>Course ID</th>
                <th>Course Name</th>                
              </tr>
            </thead>
            {jobCertificates && Array.isArray(jobCertificates) && (
              <tbody>
                {jobCertificates.map((jobcertificate) => (
                  <tr key={jobcertificate.ID_YachaY_Job_Certificate}>
                    <td>{jobcertificate.ID_YachaY_Job_Certificate}</td>
                    <td>{jobcertificate.ID_YachaY_Job}</td>
                    <td>{jobcertificate.Name}</td>
                    <td>{jobcertificate.ID_YachaY_Course}</td>     
                    <td>{jobcertificate.Course_Name}</td>              
                  </tr>
                  
                ))}
              </tbody>
            )}
          </table>
        </div>
      )} </div>:  <div></div>}




  {selectionTab === 2 && activeTab === 3 ? <div>    
        {selectedUniversityId && (
        <div>
          <hr className="divider" />
          <div className="courses-section">
            <h2>Available Courses:</h2>
            <div className="users-actions">
            <form className="form" onSubmit={handleInsertCourse}>
              <input type="text" name="CourseName" placeholder="Course Name" />       
              <input type="text" name="CourseField" placeholder="Field" />   
              <input type="text" name="CourseTopic" placeholder="Topic" />          
              <button type="submit">Insert</button>
            </form>
              <form className="form">
                <input
                  type="number"
                  value={deleteCourseId}
                  onChange={(e) => setDeleteCourseId(e.target.value)}
                  placeholder="Course ID"
                />
                <button type="button" className="btn-delete" onClick={handleDeleteCourse}>
                  Delete
                </button>
              </form>
              <form className="form" onSubmit={handleUpdateCourse}>        
                <input
                  type="number"
                  value={updateCourseID}
                  onChange={(e) => setUpdateCourseID(e.target.value)}
                  placeholder="Course ID"
                />        
                <input
                  type="text"
                  value={updateCourseName}
                  onChange={(e) => setUpdateCourseName(e.target.value)}
                  placeholder="New Name"
                />
                 <input
                  type="text"
                  value={updateCourseField}
                  onChange={(e) => setUpdateCourseField(e.target.value)}
                  placeholder="New Field"
                />
                 <input
                  type="text"
                  value={updateCourseTopic}
                  onChange={(e) => setUpdateCourseTopic(e.target.value)}
                  placeholder="New Topic"
                />
                <button type="submit" className="btn-update">
                  Update
                </button>
              </form>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th>Course ID</th>
                  <th>Internal Course ID</th>
                  <th>Name</th>
                  <th>Field</th>
                  <th>Topic</th>
                </tr>
              </thead>
              <tbody>
                {courses.map((course) => (
                  <tr key={course.ID_YachaY_Course}>
                    <td>{course.ID_YachaY_Course}</td>
                    <td>{course.Internal_Course_ID}</td>
                    <td>{course.Name}</td>
                    <td>{course.Field}</td>
                    <td>{course.Topic}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

        </div>
      )}
        </div>:  <div></div>}  


 {/*    {activeTab === 4 ? <div style={{ alignItems: 'center', paddingTop: '40px' }}>   
        <button id="academic-btn" onClick={() => handleCertificationVerification('academic')}>Academic Verification</button>
        <div id="json-section-academic" class="json-section"></div> 
        <button id="professional-btn" onClick={() => handleCertificationVerification('professional')}>Professional Verification</button>
        <div id="json-section-professional" class="json-section"></div>  
            
        </div>:  <div></div>}   */}
    
    {selectionTab === 1 ?
    <Router>
      <TabSelector/>
      <Routes>
        <Route path="/UniversityPortal/CertificationManager" element={<UniversityPortal_Certification/>} />
        <Route path="/UniversityPortal/UniqueEquivalences" element={<UniversityPortal_AllEquivalences/>} />
        <Route path="/UniversityPortal/CompanyManager" element={<UniversityPortal_ManageCompanies/>} />
        <Route path="/UniversityPortal/CourseManager" element={<UniversityPortal_Courses/>} />
        <Route path="/CompanyPortal/CertifyWorker" element={<CompanyPortal/>} />        
        <Route path="/BlockchainIntegration" element={<BlockchainIntegration/>} />
      </Routes>
    </Router>
    : <div></div>}
    </div>
    
  );
  
}

export default App;

 
