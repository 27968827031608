// Login component
import React, { useState } from 'react';

const Login = ({ fetchAccessLevel }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [accessLevel, setAccessLevel] = useState('');
  const [accessUniID, setAccessUniID] = useState('');


  const handleLogin = () => {
    // Send login request to the server
    fetch('/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // Save access level in local storage
          localStorage.setItem('accessLevel', data.accessLevel);
          localStorage.setItem('username', data.username);
          fetchAccessLevel(); 
          setLoginSuccess(true); // Set the login success state
          setAccessLevel(data.accessLevel);
          setAccessUniID(parseInt(data.username.slice(-1)));

        } else {
          setError(data.message);
        }
      })
      .catch((error) => {
        setError('An error occurred');
      });
  };

// Render the login component if login success state is false
if (!loginSuccess) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: '50px' }}>
        {error && <p>{error}</p>}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
          <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
          <button
            style={{
              backgroundColor: '#000',
              color: '#fff',
              padding: '10px',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              marginLeft: '10px',
            }}
            onClick={handleLogin}
          >
            Login
          </button>
        </div>
      </div>
    );
  }  

    // Render the header based on the access type
    if (accessLevel === 'university') {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: '50px' }}>
            <h2>Welcome to University #{accessUniID} Database. </h2>
            </div>
        );
    }
    if (accessLevel === 'admin') {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: '50px' }}>
            <h2>Welcome Admin. Start by selecting the University Database. </h2>
            </div>
        );
    }

  // Render null if login success state is true (component is hidden)
  return null;
};

export default Login;