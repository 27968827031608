import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';

const UniversityPortal_Courses = () => {


  const universityId = '1';
  const [universityName, setUniversityName] = useState('');
  const [courses, setCourses] = useState([]);

  useEffect(() => {    
    fetchCourses(universityId);    
    fetchUniversityName();     
}, []);

    const fetchUniversityName = async () => {
        try {
        const response = await axios.get(`/UniversityPortal/UniversityName/${universityId}`);        
        setUniversityName(response.data);
        } catch (error) {
        console.error('Error fetching university name:', error);
        }
    };  

    const fetchCourses = async (universityId) => {
        //const universityId = universityId;
        try {
          const response = await axios.get(`/courses/${universityId}`);
          setCourses(response.data);
          console.log(response.data);
        } catch (error) {
          console.error('Error fetching courses:', error);
        }
      };

    const handleInsertCourse = async (e) => {
        e.preventDefault();
        const CourseName = e.target.elements.CourseName.value;
        const CourseField = e.target.elements.CourseField.value;
        const CourseTopic = e.target.elements.CourseTopic.value;
        console.log(e);
        
        try {
            const lastCourseIdResponse = await fetch(`/last/course/${universityId}`, {
            method: 'GET',
            });
        
            if (!lastCourseIdResponse.ok) {
            console.error('Failed to fetch last course internal ID:', lastCourseIdResponse.status);
            return;
            }
        
            const lastCourseIdData = await lastCourseIdResponse.json();
            const lastCourseId = lastCourseIdData.lastCourseId;
            const newCourseId = lastCourseId + 1;
            console.log('last Course Internal ID:', lastCourseId);
        
            const insertCourseResponse = await fetch('/insert/course', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                CourseName,
                CourseField,
                CourseTopic,
                newCourseId: newCourseId,
                universityId,
            }),
            });
        
            if (insertCourseResponse.ok) {        
            fetchCourses(universityId);
            e.target.reset();
            } else {
            console.error('Failed to insert Course:', insertCourseResponse.status);
            }
        } catch (error) {
            console.error('Error inserting Course:', error);
        }
    };
      
 
  return (
    <div className="university-portal-courses">      
      <div className="container">
      <h1 className="subtitle">Welcome to the University YachaY Portal</h1>
        <div className="frame">
          <div className="frame-content">
            <h1 className="subtitle-uni">{universityName}</h1> 
          </div>
        </div>    
          <hr className="divider" />
          <h1>List of University Courses</h1>
          <form className="form" onSubmit={handleInsertCourse}>
              <input type="text" name="CourseName" placeholder="Course Name" />       
              <input type="text" name="CourseField" placeholder="Field" />   
              <input type="text" name="CourseTopic" placeholder="Topic" />          
              <button type="submit">Insert</button>
          </form>
          <table className="table">
              <thead>
                <tr>
                  <th>Course ID</th>
                  <th>Internal Course ID</th>
                  <th>Name</th>
                  <th>Field</th>
                  <th>Topic</th>
                </tr>
              </thead>
              <tbody>
                {courses.map((course) => (
                  <tr key={course.ID_YachaY_Course}>
                    <td>{course.ID_YachaY_Course}</td>
                    <td>{course.Internal_Course_ID}</td>
                    <td>{course.Name}</td>
                    <td>{course.Field}</td>
                    <td>{course.Topic}</td>
                  </tr>
                ))}
              </tbody>
            </table>
      </div>   
    </div>
  );
};

export default UniversityPortal_Courses;
