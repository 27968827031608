import React, { useState, useEffect } from "react";
import axios from "axios";
import "./App.css";

const UniversityPortal_ManageCompanies = () => {

  const universityId = "1";

  const [companies, setCompanies] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState("");  
  const [selectedJobId, setSelectedJobId] = useState("");
  const [jobCertificates, setJobCertificates] = useState([]);
  const [universityName, setUniversityName] = useState("");
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");
  

  useEffect(() => {
    fetchCourses(universityId);
    fetchUniversityName();
    fetchCompanies(universityId);
    

  }, []);

  const fetchUniversityName = async () => {
    try {
      const response = await axios.get(`/UniversityPortal/UniversityName/${universityId}`);
      setUniversityName(response.data);
    } catch (error) {
      console.error("Error fetching university name:", error);
    }
  };

  const fetchCompanies = async (universityId) => {    
    try {
      const response = await axios.get(`/companies/${universityId}`);
      setCompanies(response.data);
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  };



  const fetchJobCertificatesPositions = async (companyId) => {
    
    try {
      const response = await axios.get(`/UniversityPortal/jobCertificates/${companyId}}`);
      setJobCertificates(response.data);  //with Positions included
      console.log(response.data);
    } catch (error) {
      console.error('Error fetching jobs certificates and positions:', error);
    }
  };

  const fetchCourses = async (universityId) => {
    try {
      const response = await axios.get(`/courses/${universityId}`);
      setCourses(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  //handlers

  const handleCompanyClick = async (companyId) => {
    if (companyId === selectedCompanyId) {
      setSelectedCompanyId('');
      setCompanies([]);      
    } else {
      setSelectedCompanyId(companyId);
       try {        
        fetchJobCertificatesPositions(companyId);
      } catch (error) {
        console.error('Error fetching company jobs:', error);
      } 
    }
  };

  const handleInsertCompany = async (e) => {
    e.preventDefault();
    const CompanyName = e.target.elements.CompanyName.value;
  
    try {
      const lastCompanyIdResponse = await fetch(`/last/company`, {
        method: 'GET',
      });
  
      if (!lastCompanyIdResponse.ok) {
        console.error('Failed to fetch last company ID:', lastCompanyIdResponse.status);
        return;
      }
  
      const lastCompanyIdData = await lastCompanyIdResponse.json();
      const lastCompanyId = lastCompanyIdData.lastCompanyId;
      const newCompanyId = lastCompanyId + 1;
      //console.log('last Company ID:', lastCompanyId);


      const lastCompanyInternalIdResponse = await fetch(`/last/company/internal/${universityId}`, {
        method: 'GET',
      });
  
      if (!lastCompanyInternalIdResponse.ok) {
        console.error('Failed to fetch last company ID:', lastCompanyInternalIdResponse.status);
        return;
      }
  
      const lastCompanyInternalIdData = await lastCompanyInternalIdResponse.json();
      const lastCompanyInternalId = lastCompanyInternalIdData.lastInternalCompanyId;
      const newCompanyInternalId = lastCompanyInternalId + 1;
      console.log('last Company Internal ID:', lastCompanyInternalId);
  
      const insertCompanyResponse = await fetch('/insert/company', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          CompanyName,
          newCompanyId: Number(newCompanyId),
          newCompanyInternalId: Number(newCompanyInternalId),
          universityId: Number(universityId),
        }),
      });
  
      if (insertCompanyResponse.ok) {        
        fetchCompanies(universityId);
        e.target.reset();
      } else {
        console.error('Failed to insert Company:', insertCompanyResponse.status);
      }
    } catch (error) {
      console.error('Error inserting Company:', error);
    }
  };

  const handleInsertJob = async (e) => {
    e.preventDefault();
    const JobName = e.target.elements.jobName.value;
  
    try {
      const lastJobIdResponse = await fetch(`/last/job/${selectedCompanyId}`, {
        method: 'GET',
      });
  
      if (!lastJobIdResponse.ok) {
        console.error('Failed to fetch last job ID:', lastJobIdResponse.status);
        return;
      }
  
      const lastJobIdData = await lastJobIdResponse.json();
      const lastJobId = lastJobIdData.lastJobId;
      const newJobId = lastJobId + 1;
      //console.log('last Job ID:', lastJobId);


      const lastCompanyInternalIdResponse = await fetch(`/last/job/internal/${universityId}/${selectedCompanyId}`, {
        method: 'GET',
      });
  
      if (!lastCompanyInternalIdResponse.ok) {
        console.error('Failed to fetch last Company internal ID:', lastCompanyInternalIdResponse.status);
        return;
      }
  
      const lastCompanyInternalIdData = await lastCompanyInternalIdResponse.json();
      const lastCompanyInternalId = lastCompanyInternalIdData.lastInternalCompanyId;
      const newCompanyInternalId = lastCompanyInternalId + 1;
      console.log('last Company Internal ID:', lastCompanyInternalId);
  
      const insertJobResponse = await fetch('/insert/job', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          JobName,
          universityId: Number(universityId),
          companyId: Number(selectedCompanyId),
          newJobId: Number(newJobId),
          newCompanyInternalId: Number(newCompanyInternalId),          
        }),
      });  
  
      if (insertJobResponse.ok) {        
        //fetchJobs(selectedCompanyId);         
        e.target.reset();
        return JobName;
      } else {
        console.error('Failed to insert Job:', insertJobResponse.status);
      }
    } catch (error) {
      console.error('Error inserting Job:', error);
    }    
  };

  const handleInsertJobCertificate = async (JobName) => {
    
    
    const lastJobIdResponse = await fetch(`/last/job/${selectedCompanyId}`, {
        method: 'GET',
    });
    const JobId1 = await lastJobIdResponse.json();
    const JobId = JobId1.lastJobId;
    console.log('last Job ID:', JobId);
    const PositionName = JobName;
    const CertificateName = PositionName + " Certificate";

    const responseCourseID = await axios.get(`/UniversityPortal/CourseId/${selectedCourse}`);
    const CertificateCourseID2 = responseCourseID.data;
    const CertificateCourseID = 1;
    console.log('Course ID:', CertificateCourseID2);
    const CertificateCourseName = selectedCourse;   
  
    try { 
  
      const insertJobCertificateResponse = await fetch('/insert/jobCertificates', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          JobId,
          CertificateName,
          CertificateCourseID,
          CertificateCourseName,
        }),
      });  
  
      if (insertJobCertificateResponse.ok) {        
        fetchJobCertificatesPositions(selectedCompanyId);
        //e.target.reset();
      } else {
        console.error('Failed to insert Job Certificate:', insertJobCertificateResponse.status);
      }
    } catch (error) {
      console.error('Error inserting Job Certificate:', error);
    }
  };

  const handleInsertJobandJobCertificate = async (e) => {
    e.preventDefault();
    const JobName = await handleInsertJob(e);     
    handleInsertJobCertificate(JobName); 
    };



  return (
    <div className="university-portal-managecompanies">
      <div className="container">
      <h1 className="subtitle">Welcome to the University YachaY Portal</h1>
        <div className="frame">
          <div className="frame-content">
            <h1 className="subtitle-uni">{universityName}</h1> 
          </div>
        </div>  
        <hr className="divider" />
        <h1>List of Associated Companies</h1>
        <h1 className="description">Press an associated company to manage their job certificates or associate a new company</h1>
      </div>
      <div className="input-container2">
        <form className="form" onSubmit={handleInsertCompany}>
          <input type="text" name="CompanyName" placeholder="Company Name" />              
          <button type="submit">Insert</button>
        </form>
      </div>
      <table className="table">
          <thead>
            <tr>
              <th>Company ID</th>   
              <th>Internal ID</th>           
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {companies.map((job) => (
              <tr
                key={job.ID_YachaY_Company}
                onClick={() => handleCompanyClick(job.ID_YachaY_Company)}
                className={selectedCompanyId === job.ID_YachaY_Company ? 'selected' : ''}
              >
                <td>{job.ID_YachaY_Company}</td>                     
                <td>{job.ID_Internal_Company}</td>             
                <td>{job.Name}</td>

              </tr>
            ))}
          </tbody>
        </table>
                

        {selectedCompanyId && (
        <div>          
          <h1>List of Available Company Job Certification</h1>
          <h1 className="description">Manage company job certificates. Associate an internal course to a job position</h1>
          <div className="input-container2">
            <label htmlFor="selectCourse" style={{ fontWeight: "bold" }}>
              Select Course:
            </label>
            <select
              id="selectCourse"
              value={selectedCourse}
              onChange={(e) => setSelectedCourse(e.target.value)}
            >
              <option value="">-- Select Course --</option>
              {courses.map((course) => (
                <option key={course.id} value={course.Name}>
                  {course.Name}
                </option>
              ))}
            </select>
            <form className="form" onSubmit={handleInsertJobandJobCertificate}>        
              <input type="text" name="jobName" placeholder="Job Position Name" />
              <button type="submit">Add Job Position</button>
            </form>          
          </div>

          <table className="table">
            <thead>
              <tr>
                <th>Job ID</th>  
                <th>Job Position</th>                      
                <th>Associated Certificate</th>
                <th>Associated Course</th>
              </tr>
            </thead>
            {Array.isArray(jobCertificates) && (
              <tbody>
                {jobCertificates.map((jobCertificate) => (
                  <tr key={jobCertificate.ID_YachaY_Job}>                      
                    <td>{jobCertificate.ID_YachaY_Job}</td>              
                    <td>{jobCertificate.jobName}</td>                    
                    <td>{jobCertificate.Name}</td>   
                    <td>{jobCertificate.Course_Name}</td>                        
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
        
      )}

      

    </div>
  );
};

export default UniversityPortal_ManageCompanies;
